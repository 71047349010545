// i18n
import '../locales/i18n';

// highlight
import '../utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import '../utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import PropTypes from 'prop-types';
import cookie from 'cookie';
// next
import Head from 'next/head';
import App from 'next/app';
// utils
import { getSettings } from '../utils/getSettings';
// contexts
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext';
// theme
import ThemeProvider from '../theme';
// components
import { ChartStyle } from '../components/chart';
import ProgressBar from '../components/ProgressBar';
import NotistackProvider from '../components/NotistackProvider';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';
// locales
import ThemeLocalization from '../locales';
import { Analytics } from '@vercel/analytics/react';
import { GoogleTagManager } from '@next/third-parties/google';

// Maintenance
/* import Maintenance from "src/pages/maintenance";
import axios from "../utils/axios";
import qs from "qs"; */

// ----------------------------------------------------------------------

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  settings: PropTypes.object,
};

export default function MyApp(props) {
  /*   const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const query = qs.stringify(
    {
      populate: "*",
    },
    {
      encodeValuesOnly: true,
    }
  ); */

  /*   useEffect(async () => {
    setLoading(true);
    const getMaintenance = await axios
      .get(`/api/setting?${query}`)
      .then((response) => {
        console.log(response.data.data.attributes.Option[0].value);
        setOpen(response.data.data.attributes.Option[0].value);
      })
      .catch((ex) => console.error(ex));
    setLoading(false); // (2) write some value to state
  }, [open]);

  console.log(open); */
  /*   if (open === "true") {
    return <Maintenance />;
  } */

  const { Component, pageProps, settings } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta
          name="google-site-verification"
          content="dWfwGC4rj6Pe2t2b5wuz9V7dUxHb54GZhntCCiBj3mA"
        />
        <GoogleTagManager gtmId="GTM-K3QHR253" />
      </Head>
      <CollapseDrawerProvider>
        <MotionLazyContainer>
          <ThemeLocalization>
            <ThemeProvider>
              <NotistackProvider>
                <ChartStyle />
                <ProgressBar />
                {getLayout(<Component {...pageProps} />)}
                <Analytics />
              </NotistackProvider>
            </ThemeProvider>
          </ThemeLocalization>
        </MotionLazyContainer>
      </CollapseDrawerProvider>
    </>
  );
}

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie
  );

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};
